import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w12LD01Schnuppern = () => (
  <Layout>
    <SEO title="w12LD01Schnuppern" />

    <h1 id="ueberschrift">Line Dance</h1>
    <h2>Schnuppertreff für Interessierte &emsp;  ©&nbsp;2019</h2>

      <p>
        Kontakt:&ensp;
       <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w12LD01Schnuppern&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        *Line Dance* bietet eine Vielfalt an Tänzen, welche alleine oder in der Gruppe
        zu verschiedenen Liedern getanzt werden können. Zumeist wird eine Verbindung zur
        Country-Musik gesehen. Dies ist nur eine der vielen möglichen Musikrichtungen.
        Die meisten Choreographien sind weltweit einheitlich, was Ihnen die Möglichkeit
        gibt, mit Line Dancern aus aller Herren Länder den Tanz zu genießen.
      </p>
      <p>
        Meist ist es einfacher etwas zu verstehen, das erlebt oder zumindest angeschaut werden kann.
        Sie können die Gelegenheit nutzen und einen ersten Eindruck gewinnen,
        indem Sie sich über die angegebene Kontaktmöglichkeit melden. Gemeinsam ermitteln wir dann den
        für Sie günstigsten Termin, damit Sie in das Themengebiet "hineinschnuppern" können.
      </p>
      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Informationskurs (+Mitmachkurs)
      </p>
      <p>
        für alle Altersgruppen<br/>
        Materialbedarf: keiner
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig + <br/>
      </p>



    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
    <p></p>
  </Layout>
              )

              export default w12LD01Schnuppern
